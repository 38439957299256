import React from 'react';
import ReactDom from 'react-dom';
import './index.css';

import task_tock_icon from './images/task-tock.png'; // Tell Webpack this JS file uses this image

const Body = () => {
  return(
    <div id="body">
      <Header/>
      <div class="card_container">
      <Card
        img={task_tock_icon}
        link='https://apps.apple.com/us/app/task-tock-timed-task-manager/id1440183217'
        title='Task Tock - Timed Task Manager'
        description='Schedule how you spend your time. Create Tasks with how much time you want to spend on them each day instead of scheduling each hour of the day. Play and Pause your tasks as you do them.
         Customize the duration and schedule of each task and the period in which you want to reach your time goal (daily, weekly, monthly, by a specific date, on recurring days of the week, or on specific dates.
           Receive notifications for when you have reached your time goal for a task, and choose music from your library to alert you with. Go to activities tab and see your history in a 24-hour animating clock color-coated to your tasks'
      />
      </div>
      <Footer/>
    </div>
  );
}

const Header = () => {
  return(
    <div className='header'>
      <span className='header-title'>
        Chubimaukinations LLC
      </span>
      <br/>
      <span className="header-text">
        A Mobile/Desktop Application Development Company
      </span>
    </div>
  );
}

const Footer = () => {
  return(
    <div className='footer'>
      <div className='footer-title'>
        Contact Us:
      </div>
      <br/>
      <div className="footer-text">
        chubimauk@gmail.com
      </div>
    </div>
  );
}


/*
const Card = (props) => {
  return(
    <div className={props.className} >
      <div clasName='small-div'>
        <i className={props.className}></i>
        <img src={props.img} alt=''/>
      </div>
      <div className='big-div'>
        <span className='div-title'>
          {props.title}
        </span>
        <br/>
        <span>
          {props.description}
        </span>
      </div>
    </div>
      );
}*/

const Card = (props) => {
  return(
    <div className='card-row-container' >
      <div className='card-row-left-image-container'>
        <a href={props.link}>
        <img className='aspect-fit-image' src={props.img} alt=''/>
        </a>
      </div>
      <div className='card-row-right-container-column'>
        <span className='div-title'>
          {props.title}
        </span>
        <br/>
        <span className='div-description'>
          {props.description}
        </span>
      </div>
    </div>
      );
}


ReactDom.render(<Body/>, document.getElementById('root'));

/*
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
*/
